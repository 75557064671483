var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col min-h-screen"},[_c('div',{staticClass:"mx-auto mt-4 md:mt-24 lg:mt-32"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"h-16",attrs:{"src":require("@/app/assets/img/AI REDIGIO5_0.webp"),"alt":"logo"}})])],1),_c('div',{staticClass:"flex max-w-xs mx-auto my-12 bg-white rounded-lg shadow-md md:max-w-lg lg:max-w-4xl"},[_c('div',{staticClass:"w-screen px-4 py-8 lg:p-8"},[_c('validation-observer',{ref:"loginForm",staticClass:"flex flex-col space-y-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-wrap md:flex-row"},[_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide uppercase text-primary-600",attrs:{"for":"firstName"}},[_vm._v("First Name")]),_c('validation-provider',{attrs:{"vid":"firstName","name":"First name","rules":{ required: true, regex: /^[a-zA-Z0-9-. ]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.firstName),expression:"userData.firstName"}],staticClass:"input-block focus:ring",attrs:{"type":"text","placeholder":"First Name"},domProps:{"value":(_vm.userData.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "firstName", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide uppercase text-primary-600",attrs:{"for":"lastName"}},[_vm._v("Last Name")]),_c('validation-provider',{attrs:{"vid":"lastName","name":"Last name","rules":{ required: true, regex: /^[a-zA-Z0-9-. ]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.lastName),expression:"userData.lastName"}],staticClass:"input-block focus:ring",attrs:{"type":"text","placeholder":"Last Name"},domProps:{"value":(_vm.userData.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "lastName", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide uppercase text-primary-600",attrs:{"for":"username"}},[_vm._v("Username")]),_c('validation-provider',{attrs:{"vid":"username","name":"Username","rules":{ required: true, regex: /^[a-zA-Z0-9-.]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.username),expression:"userData.username"}],staticClass:"input-block focus:ring",attrs:{"type":"text","placeholder":"Username"},domProps:{"value":(_vm.userData.username)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "username", $event.target.value)},function($event){_vm.isRegistrationOpen ? (_vm.showMessage = false) : null}]}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]),(_vm.showMessage && _vm.isRegistrationOpen)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide uppercase text-primary-600",attrs:{"for":"email"}},[_vm._v("Email")]),_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.email),expression:"userData.email"}],staticClass:"input-block focus:ring",attrs:{"type":"email","placeholder":"Email"},domProps:{"value":(_vm.userData.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "email", $event.target.value)},function($event){_vm.isRegistrationOpen ? (_vm.showMessage = false) : null}]}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide uppercase text-primary-600",attrs:{"for":"password"}},[_vm._v("Password")]),_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.password),expression:"userData.password"}],staticClass:"input-block focus:ring",attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.userData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "password", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide uppercase text-primary-600",attrs:{"for":"passwordRepeat"}},[_vm._v("Repeat Password")]),_c('validation-provider',{attrs:{"vid":"passwordRepeat","name":"Repeat Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.passwordRepeat),expression:"userData.passwordRepeat"}],staticClass:"input-block focus:ring",attrs:{"type":"password","placeholder":"Repeat Password"},domProps:{"value":(_vm.userData.passwordRepeat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "passwordRepeat", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-col items-center justify-between lg:flex-row lg:space-x-2"},[(_vm.enableTermsAndConditions)?_c('validation-provider',{attrs:{"name":"Terms and Conditions","rules":{ termsAndConditionsIsRequired: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('terms-and-conditions',{attrs:{"errors":errors},model:{value:(_vm.userData.termsAndConditions),callback:function ($$v) {_vm.$set(_vm.userData, "termsAndConditions", $$v)},expression:"userData.termsAndConditions"}})]}}],null,true)}):_c('div'),_c('div',{staticClass:"px-4 text-center"},[_c('tw-button',{staticClass:"tracking-wide uppercase shadow",attrs:{"color":"primary","size":"sm","disabled":invalid || _vm.loading},on:{"click":_vm.register}},[_vm._v(" Register ")])],1)],1),(!_vm.isRegistrationOpen)?_c('div',{staticClass:"flex self-center px-4 text-sm text-red-700 lg:self-end lg:flex-row"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }