





































































































































































import { defineComponent, reactive, ref, computed } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, confirmed, min, regex } from 'vee-validate/dist/rules';
import Auth from '@/modules/auth/api/auth';
import { TwButton } from '@/app/components';
import { TermsAndConditions } from '../components';

extend('required', required);
extend('regex', regex);
extend('termsAndConditionsIsRequired', {
    ...required,
    message: 'You need to read and accept the terms and conditions before proceeding',
});
extend('email', email);
extend('confirmed', { ...confirmed, message: 'Repeat Password does not match.' });
extend('min', { ...min, message: 'Password must be longer than or equal to 8 characters.' });

export default defineComponent({
    name: 'Register',
    components: { TwButton, ValidationProvider, ValidationObserver, TermsAndConditions },
    setup(props, { root }) {
        const loading = ref(false);
        const message = ref<any>(null);
        const showMessage = ref(false);
        const enableTermsAndConditions = false; // turn to true if you want to show terms and conditions component

        const userDataTemplate = computed(() => {
            if (enableTermsAndConditions) {
                return {
                    firstName: '',
                    lastName: '',
                    username: '',
                    email: '',
                    password: '',
                    passwordRepeat: '',
                    termsAndConditions: false,
                };
            }
            return {
                firstName: '',
                lastName: '',
                username: '',
                email: '',
                password: '',
                passwordRepeat: '',
            };
        });

        const userData = reactive(userDataTemplate.value);
        const register = () => {
            loading.value = true;
            Auth.register(userData)
                .then(async () => {
                    root.$router.push({ name: 'login' });
                    loading.value = false;
                })
                .catch((e) => {
                    message.value =
                        e.response.data.message === 'Registration is closed'
                            ? 'Registration is closed'
                            : 'Username or Email already exists.';
                    showMessage.value = true;
                    loading.value = false;
                });
        };

        const isRegistrationOpen = computed(() => message.value !== 'Registration is closed');

        return { enableTermsAndConditions, loading, message, showMessage, register, userData, isRegistrationOpen };
    },
});
